import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'app-custom-image',
  templateUrl: './custom-image.component.html',
  styleUrls: ['./custom-image.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class CustomImageComponent implements OnChanges {
  @Input({ required: true }) imgId = '';
  @Input({ required: true }) alt = '';
  @Input() defaultImage = '/assets/images/default/avatar.webp';

  @Input() styleClass = '';
  @Input() imageClass = '';
  imageLoader = true;
  imageUrl: SafeUrl = '';

  constructor(
    public storageService: StorageService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges() {
    this.storageService.getFile(this.imgId).subscribe({
      next: res => {
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(res));
        this.imageLoader = false;
      },
      error: () => {
        this.imageUrl = this.defaultImage;
        this.imageLoader = false;
      }
    });
  }
}
