<div
  [ngClass]="{
    'w-4 h-4': size === '2xs',
    'w-5 h-5': size === 'xs',
    'w-6 h-6': size === 'sm',
    'w-8 h-8': size === 'md',
    'w-10 h-10': size === 'lg',
    'w-12 h-12': size === 'xl',
    'w-24 h-24': size === '2xl',
    'w-32 h-32': size === '3xl'
  }"
  class="rounded-full overflow-hidden">
  @if (imgId) {
    <app-custom-image styleClass="w-full h-full" alt="Avatar" [imgId]="imgId"></app-custom-image>
  } @else {
    <div
      [ngStyle]="{ 'background-color': backgroundColor, color: textColor }"
      class="w-full h-full flex items-center justify-center text-1"
      [ngClass]="{ 'text-7': size === '2xl' }">
      {{ initials }}
    </div>
  }
</div>
