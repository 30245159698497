<div [class]="styleClass">
  <div
    class="w-full h-full bg-gray-50 flex items-center justify-center"
    [class]="imageClass"
    [ngClass]="{ hidden: !imageLoader }">
    <i class="pi pi-spin pi-spinner text-sm"></i>
  </div>
  <img
    [ngClass]="{ hidden: imageLoader }"
    [alt]="alt"
    class="w-full h-full object-cover object-center"
    [class]="imageClass"
    [src]="imageUrl" />
</div>
